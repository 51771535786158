import {Component, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LocationType} from '../../../../../pipes/fleetLocation.pipe';
import {map, switchMap, tap} from 'rxjs/operators';
import {
    buildRole,
    ChargePointCriteria,
    ChargePointDto,
    ChargePointLazyCategory,
    ChargeRecordDto,
    ChargeRecordSortingField,
    CurrentSubscriberRolesService,
    FrontChargePointHttpService,
    FrontChargeRecordHttpService,
    FrontPersonPortalContextService,
    FrontStationHttpService,
    FrontTariffHttpService,
    PersonFleetPortalContext,
    StationDto,
    StationLazyCategory,
    SubscriberRoleLevel,
    SubscriberRoleType,
    TariffDto
} from 'lib-front';
import {OthrTariffDetailDto} from 'lib-front/lib/domain/tariff/othrTariffDetailDto';

@Component({
    selector: 'station',
    templateUrl: './station.component.html',
    styleUrls: ['./station.component.scss'],
    host : {'class': 'cell auto scroll-container'}
})
export class StationComponent {
    public LocationType = LocationType;
    public selectedTemplate: TemplateRef<any>;
    public station: StationDto;
    public linkedTariffs: TariffDto[];
    public chargePoints: Array<ChargePointDto>;
    public chargeRecords: Array<ChargeRecordDto>;
    public chargeAreaId: string;
    private stationId: string;
    public hasStationWriteRole: boolean;
    public isInfraManagementTariffCreationEnabled: boolean;
    public foAccountId: string;
    public tariffsAllowSubscriberToDeclareStation: boolean;
    public canCreatePaynowTariffs: boolean;
    public othrTariff: OthrTariffDetailDto;

    constructor(private readonly route: ActivatedRoute,
        private readonly stationService: FrontStationHttpService,
        private readonly chargeRecordService: FrontChargeRecordHttpService,
        private readonly tariffHttpService: FrontTariffHttpService,
        private readonly chargePointHttpService: FrontChargePointHttpService,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService,
        private readonly personPortalContextService: FrontPersonPortalContextService) {
        this.chargeAreaId = route.snapshot.paramMap.get('chargeAreaId');
        this.stationId = route.snapshot.paramMap.get('stationId');
        stationService.findStationById(this.stationId, [
            StationLazyCategory.USER_ACTIONS,
            StationLazyCategory.ALL_PLUG_TYPES
        ])
            .pipe(
                tap(station => this.station = station),
                switchMap(station => this.chargeRecordService.find({stationRef: station.id, limit: 5,
                    sort: ChargeRecordSortingField.START_DATE})),
                tap(chargeRecords => this.chargeRecords = chargeRecords
                    .sort((a: ChargeRecordDto, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()))
            ).subscribe();
        chargePointHttpService.fetchChargePoints(new ChargePointCriteria(this.stationId, [ChargePointLazyCategory.PLUG_DTO], 100))
            .pipe(
                map(chargePoints => this.chargePoints = chargePoints
                    .sort((a, b) => a.visualId.localeCompare(b.visualId)))
            )
            .subscribe();
        this.tariffHttpService.fetchTariffs({stationId: this.stationId})
            .pipe(
                tap(tariffs => this.linkedTariffs = tariffs),
                switchMap(() => this.tariffHttpService.checkIfTariffsAllowSubscriberToDeclareStation(
                    this.linkedTariffs.map(tariff => tariff.id)
                ))
            ).subscribe((tariffStationDeclarationDto) => this.tariffsAllowSubscriberToDeclareStation = tariffStationDeclarationDto.declarationAllowed);

        this.tariffHttpService
            .getOthrPricingInfoForStation(this.stationId)
            .subscribe(othrTariffDetailDto => {
                if (!!othrTariffDetailDto?.pricingInfo) {
                    this.othrTariff = othrTariffDetailDto;
                }
            });

        this.currentSubscriberRolesService.hasRole(
            buildRole(SubscriberRoleType.CHARGING_POINTS, SubscriberRoleLevel.WRITE)
        ).subscribe((hasRole) => {
            this.hasStationWriteRole = hasRole;
        });

        this.personPortalContextService.isInfraManagementTariffCreationEnabled()
            .subscribe(isInfraManagementTariffCreationEnabled => this.isInfraManagementTariffCreationEnabled = isInfraManagementTariffCreationEnabled);

        this.personPortalContextService.currentPersonPortalContextSubject$.pipe(
            map(personPortalContext => this.foAccountId = (personPortalContext as PersonFleetPortalContext).foAccountId)
        );

        this.personPortalContextService.canCreatePaynowTariffs()
            .subscribe((canCreatePaynowTariffs) => this.canCreatePaynowTariffs = canCreatePaynowTariffs);
    }

    selectTab(template: TemplateRef<any>) {
        if (this.selectedTemplate === template) {
            this.selectedTemplate = null;
        } else {
            this.selectedTemplate = template;
        }
    }

    isSelected(stationTemplate: TemplateRef<any>): boolean {
        return this.selectedTemplate === stationTemplate;
    }

    trackByChargePointId(index: number, chargePointDto: ChargePointDto) {
        return chargePointDto?.id ?? index;
    }

    linkedTariffChanged() {
        this.tariffHttpService.fetchTariffs({stationId: this.stationId})
            .subscribe(tariffs => this.linkedTariffs = tariffs);
    }
}
